import React from 'react';
import HelmetHead from "../../components/HelmetHead";
import Logo from "../../components/Logo";

const LoginLayout = ({ children }) => {
    return <>
        <HelmetHead />
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="login-card login-dark">
                        <div className="">
                            <Logo />
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default LoginLayout;
