import React from "react";
import { Route } from "react-router-dom";
import Login from "../views/user/Login";
import Register from "../views/user/Register";

const AuthRoutes = () => {
    return (
        <>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
        </>
    );
};

export default AuthRoutes;
