import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "../views/Dashboard";

const AppRoutes = () => {
    return (
        <>
            <Route path="/dashboard" element={<Dashboard />} />
        </>
    );
};

export default AppRoutes;
