import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetHead = () => {
    return (
        <Helmet>
            <html lang="en" />
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content="Kopelah admin login page" />
            <meta name="keywords" content="retail, online, in store, start, scale, succeed, kopelah" />
            <meta name="author" content="Lemur Conseil" />
            <title>Login | Management Portal</title>
            <link rel="icon" href="/assets/images/favicon.png" type="image/x-icon" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik:400,500,700" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900" />
            <link rel="stylesheet" href="/assets/css/fontawesome.css" />
            <link rel="stylesheet" href="/assets/css/vendors/bootstrap.css" />
            <link rel="stylesheet" href="/assets/css/style.css" />
            <link rel="stylesheet" href="/assets/css/responsive.css" />
        </Helmet>
    );
};

export default HelmetHead;
