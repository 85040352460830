// src/components/SignUp.js
import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import authService from '../../services/authService';
import LoginLayout from "./LoginLayout";

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.signup(email, password);
      navigate('/dashboard');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
      <>
        <LoginLayout>
          <div className="login-main">
            <form className="theme-form" onSubmit={handleSubmit}>
              <h4>Create your account</h4>
              <p>Enter your personal details to create account</p>
              <div className="form-group">
                <label className="col-form-label pt-0">Your Name</label>
                <div className="row g-2">
                  <div className="col-sm-6">
                    <input className="form-control" type="text" required="" placeholder="First name"/>
                  </div>
                  <div className="col-sm-6">
                    <input className="form-control" type="text" required="" placeholder="Last name"/>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="col-form-label">Email Address</label>
                <input className="form-control"
                       type="email"
                       required=""
                       placeholder="test@gmail.com"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Password</label>
                <div className="form-input position-relative">
                  <input className="form-control" type="password" name="login[password]"
                         required=""
                         placeholder="*********"
                         value={password}
                         onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="show-hide"><span className="show"></span></div>
                </div>
              </div>
              <div className="form-group mb-0">
                <div className="checkbox p-0">
                  <input id="checkbox1" type="checkbox"/>
                  <label className="text-muted" htmlFor="checkbox1">Agree with<a className="ms-2" href="#">Privacy
                    Policy</a></label>
                </div>
                <button className="btn btn-primary btn-block w-100" type="submit">Create Account</button>
              </div>
              <hr/>
              <p className="mt-4 mb-0">
                Already have an account?&nbsp;
                <Link to="/login">Sign in here</Link>
              </p>
            </form>

          </div>
        </LoginLayout>
      </>
  );
}

export default Register;

