// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/scss/style.scss';
import AuthRoutes from "./routes/AuthRoutes";
import AppRoutes from "./routes/AppRoutes";
import Login from "./views/user/Login";

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<Login />} />
          {AuthRoutes()}
          {AppRoutes()}
      </Routes>
    </Router>
  );
}

export default App;

