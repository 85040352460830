// src/services/authService.js
import axios from 'axios';

const API_URL = 'http://localhost:8080/api'; // Replace with your local server URL

const authService = {
  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, { email, password });
      const user = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Login failed');
    }
  },

  signup: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/signup`, { email, password });
      const user = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Sign-up failed');
    }
  },

  logout: () => {
    localStorage.removeItem('user');
  },
};

export default authService;

