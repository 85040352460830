import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import authService from '../../services/authService';
import LoginLayout from './LoginLayout';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.login(email, password);
      navigate('/dashboard');
    } catch (error) {
      alert('Login failed. Please check your credentials.');
    }
  };

  return (
      <>
        <LoginLayout>
          <div className="login-main">
            <form className="theme-form" onSubmit={handleSubmit}>
              <h4>Sign in to your account</h4>
              <p>Enter your email & password to login</p>
              <div className="form-group">
                <label className="col-form-label">Email Address</label>
                <input
                    className="form-control"
                    type="email"
                    required
                    placeholder="test@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Password</label>
                <div className="form-input position-relative">
                  <input
                      className="form-control"
                      type="password"
                      required
                      placeholder="*********"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mb-0">
                <div className="checkbox p-0">
                  <input id="checkbox1" type="checkbox"/>
                  <label className="text-muted" htmlFor="checkbox1">
                    Remember password
                  </label>
                </div>
                <a className="link" href="/forgot-password">
                  Forgot password?
                </a>
                <div className="text-end mt-3">
                  <button className="btn btn-primary btn-block w-100" type="submit">
                    Sign in
                  </button>
                </div>
              </div>
              <hr/>
              <p className="mt-4 mb-0 text-center">
                Don't have an account?&nbsp;
                <Link to="/register">Register here</Link>
              </p>
            </form>
          </div>
        </LoginLayout>
      </>
  );
};

export default Login;
