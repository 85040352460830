// src/components/Dashboard.js
import React from 'react';

function Dashboard() {
  return (
    <div>
      <h2>Welcome to the Dashboard!</h2>
      <p>You are now logged in.</p>
    </div>
  );
}

export default Dashboard;

